import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'cil-speedometer',
  },
  {
    name: 'Orders',
    url: '/orders',
    icon: 'cil-chart',
    children: [
      {
        name: 'All Orders',
        url: '/orders/all',
      },
      {
        name: 'Create Order',
        url: '/orders/create',
      }
    ],
  },
  // {
  //   name: 'Credit Notes',
  //   url: '/credit-notes',
  //   icon: 'cil-notes',
  // },
  {
    name: 'Products',
    url: '/products',
    icon: 'cil-gift',
    children: [
      {
        name: 'All Products',
        url: '/products/all',
      },
      {
        name: 'Categories',
        url: '/products/categories'
      },
    ],
  },
  {
    name: 'Vouchers',
    url: '/vouchers',
    icon: 'cil-gift',
    children: [
      {
        name: 'All Vouchers',
        url: '/vouchers/all',
      },
      {
        name: 'Create Voucher',
        url: '/vouchers/create',
      },
    ],
  },
  {
    name: 'Quote Requests',
    url: '/quote-requests',
    icon: 'cil-gift',
  },
  {
    name: 'Customers',
    url: '/customers/all',
    icon: 'cil-people',
  },
  {
    name: 'Contact Us',
    url: '/contact-us',
  },

  // {
  //   name: 'Admins',
  //   url: '/admins/all',
  //   icon: 'cil-user-plus',
  // },
  {
    name: 'Banners',
    url: '/banners',
    icon: 'cil-calendar-check',
  },
  {
    name: 'Manufacturers',
    url: '/manufacturers',
    icon: 'cil-diamond',
  },
  {
    name: 'Featured Categories',
    url: '/featured-categories',
    icon: 'cil-grid',
  },
  {
    name: 'Vacancies',
    url: '/vacancies',
    icon: 'cil-featured-playlist',
  },
  {
    name: 'News',
    url: '/news',
    icon: 'cil-featured-playlist',
    children: [
      {
        name: 'News Articles',
        url: '/news/articles/all',
      },
      {
        name: 'News Categories',
        url: '/news/categories/all',
      }
    ],
  },
  {
    name: 'E-Brochures',
    url: '/e-brochures',
    icon: 'cil-featured-playlist',
  },
  {
    name: 'Settings',
    url: '/settings',
    icon: 'cil-settings',
    children: [
      {
        name: 'Taxes',
        url: '/settings/taxes/all',
      },
      {
        name: 'Attributes',
        url: '/settings/attributes/all',
      },
      {
        name: 'Surcharges',
        url: '/settings/surcharges/all',
      },
      {
        name: 'Engineered solutions',
        url: '/settings/engineered-solutions/all'
      },
      {
        name: "Rebuild Elastic Search",
        url: '/settings/rebuild-elastic',
      }
    ],
  },
  {
    name: 'Log Out',
    url: '/login/loggedout',
    icon: 'cil-account-logout',
    class: 'bottom'
  },
];
