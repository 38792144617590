import { Injectable, ChangeDetectorRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { from } from 'rxjs';
// import { MessageService } from './global/message/message.service';
import { Router } from '@angular/router';
import { UtilService } from './global/util.service';
import { SessionService } from './global/session.service';
// import { faTreeChristmas } from '@fortawesome/pro-light-svg-icons';

@Injectable({
  providedIn: 'root'
})
export class CandyService {

  // REMOVED AUTH AS A TEST
  public hostname: String = 'https://hc-ecom-api.broadlanddigital.co.uk/'; //https for production
  public api_host: String = 'https://hc-ecom-api.broadlanddigital.co.uk/'; //https for production
  // public hostname: String = 'http://hc-ecom-api.local/'; //https for production
  // public api_host: String = 'http://hc-ecom-api.local/'; //https for production
  // public api_user: String = 'fusionteam';
  // public api_password: String = 'password123!!!';

  /*
  private consumer_key: String = 'k0tq45ucpadv4rdunshyh8sg3fv6bhtn';
  private consumer_secret: String = '48sbvzk4igi7jedvvs105elbxvghkb3t';
  private access_token: String = 'z828w3qr5lcu4hzudy1ol8gvq8rvb08c';
  private access_token_secret: String = '46gjbtp52kjhy3cw9xqosn772johf0rw';
  */

  constructor(private http: HttpClient, private util: UtilService, public session: SessionService, private router: Router) {

  }

  createRequest(type = 'GET', url, payload = null, token = this.util.getCookie('admin_token'), otherParams = {}): Observable<any> {
    return this.getData(type, url, payload, token, otherParams);

    // return from(this.getToken()).pipe(mergeMap(response => {
    //   if(token == null){
    //     return this.getData(type, url, payload);
    //   } else {
    //     return this.getData(type, url, payload, token);
    //   }
    // }));
  }

  // getToken(){
  //   var promise = new Promise((resolve, reject) => {
  //     if(!this.util.getCookie("api_token")){
  //       var options = {username: this.api_user, password: this.api_password}
  //       this.http.post(this.api_host+'V1/integration/admin/token', options, {headers: {'Content-Type': 'application/json; charset=utf-8'}, observe: 'response'}).subscribe(response =>{
  //         if(response.status == 200){
  //             this.util.setCookie('api_token', response.body, 4); //4 = 4 hours to match magento's token timeout
  //           resolve();
  //         } else {
  //           reject();
  //         }
  //       });
  //     } else {
  //       resolve();
  //     }
  //   });

  //   return promise;

  // }

  getData(type = 'GET', url, payload = null, token = this.util.getCookie("admin_token"), otherParams = {}) {
    if (type == 'GET') {
      return this.http.get(this.api_host + url, { headers: { 'Authorization': 'Bearer ' + token }, ...otherParams});
    } else if (type == 'POST') {
      return this.http.post(this.api_host + url, payload, { headers: { 'Content-Type': 'application/json', 'responseType': 'text', 'Authorization': 'Bearer ' + token } });
    } else if (type == 'POST-CSV') {
      return this.http.post(this.api_host + url, payload, { headers: { 'Content-Type': 'application/json; charset=utf-8', 'responseType': 'text', 'Authorization': 'Bearer ' + token }, responseType: 'text' });
    } else if (type == 'POST-IMG') {
      return this.http.post(this.api_host + url, payload, { headers: { 'Accept': 'application/json', 'Authorization': 'Bearer ' + token } });
    }
    else if (type == 'PUT') {
      return this.http.put(this.api_host + url, payload, { headers: { 'Content-Type': 'application/json; charset=utf-8', 'Authorization': 'Bearer ' + token } });
    } else if (type == 'DELETE') {
      return this.http.delete(this.api_host + url, { headers: { 'Content-Type': 'application/json; charset=utf-8', 'Authorization': 'Bearer ' + token } });
    } else if (type == 'PATCH') {
      return this.http.patch(this.api_host + url, payload, { headers: { 'Content-Type': 'application/json; charset=utf-8', 'Authorization': 'Bearer ' + token } }) 
    } else {
      return null;
    }
  }

  // getCustomerToken(username, password){
  //   var promise = new Promise((resolve, reject) => {
  //     var options = {username: username, password: password}
  //     this.http.post(this.api_host+'V1/integration/customer/token', options, {headers: {'Content-Type': 'application/json; charset=utf-8'}, observe: 'response'}).subscribe(response =>{
  //       if(response.status == 200){
  //         this.util.setCookie('admin_token', response.body, 1); //4 = 4 hours to match magento's token timeout
  //         resolve();
  //       } else {
  //         reject('Incorrect username or password');
  //       }
  //     }, response => {
  //       reject('Incorrect username or password');
  //     });
  //   });

  //   return promise;
  // }

  // customerLogin(username, password){

  //   this.http.post(this.hostname + `api/login?email=${username}&password=${password}`, null, {headers: {'Content-Type': 'application/json; charset=utf-8'}, observe: 'response'} ).subscribe(response => {
  //     console.warn(response);
  //     this.util.setCookie('admin_token', response['access_token'], 1); //4 = 4 hours to match magento's token timeout
  //     return response;
  //   })

  //   // return from(this.getCustomerToken(username, password)).pipe(mergeMap(token => {
  //   //   var promise = new Promise((resolve, reject) => {
  //   //     this.createRequest('GET', 'V1/customers/me', null, this.util.getCookie("admin_token")).subscribe(response => {
  //   //       if(response['id']){
  //   //         this.util.setCookie('customer', JSON.stringify(response), 1); //4 = 4 hours to match magento's token timeout
  //   //         this.createRequest('GET', 'V1/carts/mine', null, this.util.getCookie("admin_token")).subscribe(response => {
  //   //           if(response){
  //   //             localStorage.setItem('cart', JSON.stringify({basket_id: response['id'], item_count: response['items_count']}));
  //   //           }
  //   //         }, response => {
  //   //           reject(response);
  //   //         });
  //   //         resolve(JSON.stringify(response));
  //   //       }
  //   //     }, response => {
  //   //         reject('Incorrect username or password');
  //   //     });
  //   //   });

  //   //   return promise;
  //   // }));
  // }

  customerLogout() {
    // this.headerService.setShowLoader(true);
    localStorage.removeItem('cart');
    this.util.deleteCookie('customer');
    this.util.deleteCookie('admin_token');
    this.util.deleteCookie('admin_refresh_token');
    this.router.navigateByUrl('/login');
    // this.headerService.setShowCartVI(false);
    // this.messageService.showMessage('You have been logged out.', 'success', true);
    // this.headerService.setShowLoader(false);
  }

  // assignCartToUser(newBasketID, product) {
  //   console.warn('new basekt ID', newBasketID);

  //   this.createRequest('POST', `api/v1/baskets/${newBasketID}/claim`).subscribe(response=>{
  //     console.warn(response);
  //     // add product to user's newly assigned card
  //     this.addToCart(product);
  //   })
  // }

  // addToCart(product): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     var token = null;
  //     var url, url2 = null;
  //     var basketID = null;

  //     if(this.util.getCookie('admin_token')){
  //         // Use customer ID, which is their tokenn when logging in, as the basket ID
  //         basketID = token =  this.util.getCookie('admin_token');
  //     }

  //       // check if user has a basket associated with their account or not
  //       url = "api/v1/baskets/current";

  //       this.createRequest("GET", url).subscribe(response=> {
  //         console.warn(response);
  //         basketID = response['data']['id'];
  //         if (basketID) {
  //           // user has basket, so use existing basket ID, then add current item to basket
  //           basketID = response['data']['id'];

  //           var request = {
  //             "basket_id": basketID,
  //             "variants": [
  //               {
  //               "id": product['selected_variant'],
  //               "quantity": product['selected_qty']
  //               }
  //             ]
  //           }

  //           url = "api/v1/basket-lines";
  //           this.createRequest('POST', url, request, token).subscribe(response => {
  //                console.warn('product added!',response);
  //                if(response){
  //                 var itemCount = response['data']['lines']['data'].length;
  //                 localStorage.setItem('cart', JSON.stringify({basket_id: basketID, item_count: itemCount}));
  //                 resolve(response);
  //               } else {
  //                 reject(response);
  //               }
  //             }, response => {
  //               reject(response['error']['message']);
  //           });


  //         } else {
  //           // user does not have a basket, so let's make a new one for them and assign it
  //           this.createRequest("POST", "api/v1/baskets").subscribe(response=> {
  //             console.warn('new basekt made', response);
  //             var newBasketID = response['data']['id'];
  //             this.assignCartToUser(newBasketID, product);
  //           })
  //         }
  //       })



  //       // if user does not have a basket, let's assign them a new one




  //           //  var basket_id = response;
  //           //  localStorage.setItem('cart', JSON.stringify({basket_id: basket_id, item_count: 1}));

  //           //  var item;

  //       //      if(product.type_id == "virtual" || product.type_id == 'simple'){
  //       //        item = {
  //       //            "cartItem": {
  //       //              "sku": product.selected_sku ? product.selected_sku : product.sku,
  //       //              "qty": product.selected_qty,
  //       //              "basket_id": basket_id,
  //       //              "price": product.price,
  //       //              "productOption": product.productOption
  //       //            }
  //       //        }
  //       //      } else if(product.type_id == "configurable") {
  //       //        reject('Please select a product option.');
  //       //      }

  //       //      if(item != null){
  //       //        if(token != null){
  //       //          url = 'default/V1/carts/mine/items';
  //       //          // url = 'V1/metpro/addtocart';
  //       //        } else {
  //       //          url = 'default/V1/guest-carts/'+basket_id+'/items';
  //       //        }

  //       //        this.createRequest('POST', url, item, token).subscribe(response => {
  //       //          if(response){
  //       //            resolve();
  //       //          } else {
  //       //            reject(response);
  //       //          }
  //       //        }, response => {
  //       //          reject(response['error']['message']);
  //       //        });
  //       //      } else {
  //       //        reject('An error occurred while adding to cart.');
  //       //      }
  //       //    } else {
  //       //      reject('An error occurred while creating the cart session.');
  //       //    }
  //       // });


  //     //Cart doesnt exist, lets create the cart
  //   //   if(false){

  //   //     // Use customer ID, which is their tokenn when logging in, as the basket ID
  //   //   basketID = token =  this.util.deleteCookie('admin_token');
  //   //   url = "api/v1/basket-lines";

  //   //     this.createRequest('GET', url, token).subscribe(response => {
  //   //       if(response){
  //   //         console.warn(response);

  //   //         var basket_id = response;
  //   //         localStorage.setItem('cart', JSON.stringify({basket_id: basket_id, item_count: 1}));

  //   //         var item;

  //   //         if(product.type_id == "virtual" || product.type_id == 'simple'){
  //   //           item = {
  //   //               "cartItem": {
  //   //                 "sku": product.selected_sku ? product.selected_sku : product.sku,
  //   //                 "qty": product.selected_qty,
  //   //                 "basket_id": basket_id,
  //   //                 "price": product.price,
  //   //                 "productOption": product.productOption
  //   //               }
  //   //           }
  //   //         } else if(product.type_id == "configurable") {
  //   //           reject('Please select a product option.');
  //   //         }

  //   //         if(item != null){
  //   //           if(token != null){
  //   //             url = 'default/V1/carts/mine/items';
  //   //             // url = 'V1/metpro/addtocart';
  //   //           } else {
  //   //             url = 'default/V1/guest-carts/'+basket_id+'/items';
  //   //           }

  //   //           this.createRequest('POST', url, item, token).subscribe(response => {
  //   //             if(response){
  //   //               resolve();
  //   //             } else {
  //   //               reject(response);
  //   //             }
  //   //           }, response => {
  //   //             reject(response['error']['message']);
  //   //           });
  //   //         } else {
  //   //           reject('An error occurred while adding to cart.');
  //   //         }
  //   //       } else {
  //   //         reject('An error occurred while creating the cart session.');
  //   //       }
  //   //     });

  //   //   } else {

  //   //     //Cart exists
  //   //     var cart = JSON.parse(localStorage.getItem('cart'));
  //   //     var basket_id = cart['basket_id'];

  //   //     var item;


  //   //     //TODO
  //   //     //Check to see if the product is already in the cart and needs to be updated
  //   //     if(product.type_id == "virtual" || product.type_id == 'simple'){
  //   //       item = {
  //   //           "cartItem": {
  //   //             "sku": product.selected_sku ? product.selected_sku : product.sku,
  //   //             "qty": product.selected_qty,
  //   //             "basket_id": basket_id,
  //   //             "price": product.price,
  //   //             "productOption": product.productOption
  //   //           }
  //   //       }
  //   //     } else if(product.type_id == "configurable") {
  //   //       reject('Please select a product option.');
  //   //     }

  //   //     //before we add to cart, lets check to make sure the customer is not mixing standard and non standard products
  //   //     // this.createRequest('GET', 'default/V1/carts/mine', null, token).subscribe(response => {
  //   //     //   if(response['items']){
  //   //     //     var items = response['items'];
  //   //     //     var vat_type = null;

  //   //     //     items.forEach(element => {
  //   //     //       if(!vat_type){
  //   //     //         var sku = element.sku;
  //   //     //         sku = sku.split("_").pop();
  //   //     //         vat_type = sku;
  //   //     //         return false;
  //   //     //       }
  //   //     //     });

  //   //     //     if(vat_type){
  //   //     //       var prod_vat_type = item['cartItem']['sku'].split("_").pop();
  //   //     //       if(vat_type == prod_vat_type){
  //   //     //         if(item != null){
  //   //     //           if(token != null){
  //   //     //             url = 'V1/carts/mine/items';
  //   //     //           } else {
  //   //     //             url = 'default/V1/guest-carts/'+basket_id+'/items';
  //   //     //           }

  //   //     //           this.createRequest('POST', url, item, token).subscribe(response => {
  //   //     //             if(response){
  //   //     //               resolve();
  //   //     //               // Add 1 to item_count in cart
  //   //     //               localStorage.setItem('cart', JSON.stringify({basket_id: basket_id, item_count: cart.item_count+1}));
  //   //     //             } else {
  //   //     //               reject(response);
  //   //     //             }
  //   //     //           }, response => {
  //   //     //             reject(response['error']['message']);
  //   //     //           });
  //   //     //         } else {
  //   //     //           reject('An error occurred while adding to cart.');
  //   //     //         }
  //   //     //       } else {
  //   //     //         reject('You cannot mix Marginal and Standard VAT products.');
  //   //     //       }
  //   //     //     } else {
  //   //     //       if(token != null){
  //   //     //         url = 'V1/carts/mine/items';
  //   //     //       } else {
  //   //     //         url = 'default/V1/guest-carts/'+basket_id+'/items';
  //   //     //       }

  //   //     //       this.createRequest('POST', url, item, token).subscribe(response => {
  //   //     //         if(response){
  //   //     //           resolve();
  //   //     //           // Add 1 to item_count in cart
  //   //     //           localStorage.setItem('cart', JSON.stringify({basket_id: basket_id, item_count: cart.item_count+1}));
  //   //     //         } else {
  //   //     //           reject(response);
  //   //     //         }
  //   //     //       }, response => {
  //   //     //         reject(response['error']['message']);
  //   //     //       });
  //   //     //     }
  //   //     //   }
  //   //     // }, response => {
  //   //     //   reject(response);
  //   //     // });
  //   //   }
  //   // });
  //     // return promise;
  //   })
  // }

  // removeFromCart(product){
  //   this.headerService.setShowLoader(true);
  //   var token = null;
  //   var url = null;

  //   var cart = JSON.parse(localStorage.getItem('cart'));
  //   var basket_id = cart['basket_id'];

  //   localStorage.setItem('cart', JSON.stringify({basket_id: basket_id, item_count: cart.item_count-1}));

  //   var promise = new Promise((resolve, reject) => {

  //     if(this.util.getCookie('admin_token')){
  //       token = this.util.getCookie('admin_token');
  //     }

  //     if(token != null){
  //       url = 'V1/carts/mine/items/'+product['id'];
  //     } else {
  //       url = 'V1/guest-carts/items/'+product['id'];
  //     }

  //     this.createRequest('DELETE', url, '', token).subscribe(response => {
  //       this.headerService.setShowLoader(false);
  //       resolve(product['name']+'has been removed from your basket');
  //       var itemCount = JSON.parse(localStorage.getItem('cart'))['item_count'];

  //       if (itemCount <=0 ) {
  //         this.headerService.setShowCartDot(false);
  //       }

  //     }, response => {
  //       this.headerService.setShowLoader(false);
  //       reject(response);
  //     });
  //   });

  //   return promise;
  // }

  // updateCart(product){
  //   this.headerService.setShowLoader(true);
  //   var token = null;
  //   var url = null;

  //   var cart = JSON.parse(localStorage.getItem('cart'));
  //   var basket_id = cart['basket_id'];

  //   var item;

  //   if(product['qty'] >= 1){
  //     var promise = new Promise((resolve, reject) => {

  //       if(this.util.getCookie('admin_token')){
  //         token = this.util.getCookie('admin_token');
  //       }

  //       if(token != null){
  //         url = 'V1/carts/mine/items/'+product['id'];
  //       } else {
  //         url = 'V1/guest-carts/items/'+product['id'];
  //       }

  //       item = {
  //         "cartItem": {
  //           "sku": product.sku,
  //           "qty": product.qty,
  //           "basket_id": basket_id
  //         }
  //       }

  //       this.createRequest('PUT', url, item, token).subscribe(response => {
  //         this.headerService.setShowLoader(false);
  //         resolve(product['Name']+'has been been updated.');
  //       }, response => {
  //         this.headerService.setShowLoader(false);
  //         reject(response);
  //       });
  //     });
  //   } else {
  //     this.removeFromCart(product).then(response => {
  //       // this.messageService.showMessage(product['Name']+' Has been removed from the cart', 'success');
  //     }, response => {
  //       // this.messageService.showMessage(response, 'error');
  //     });
  //   }


  //   return promise;
  // }
}


