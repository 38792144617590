import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public showLoader = new BehaviorSubject('ShowLoader');
  public showMessage = new BehaviorSubject('ShowMessage');

  constructor() { }

  setShowLoader(showLoader) {
    this.showLoader.next(showLoader);
  }

  setShowMessage(showMessage) {
    this.showMessage.next(showMessage);
  }

}
