import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CandyService } from '../../candy.service';
import { SessionService } from '../../global/session.service';
import { UtilService } from '../../global/util.service';
import {LoaderService} from '../../global/loader.service';
import {MessageService} from '../../global/message/message.service';


@Component({
  selector: 'app-b2b-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public showLoader = null;
  public showMessage = null;
  loginUsername: string = null;
  loginPassword: string = null;
  showUserDNE: Boolean = false;
  errorMessage: string = null;
  showRegisterPopup: Boolean = false;

  constructor(
    private router: Router,
    public session: SessionService,
    private candy: CandyService,
    private util: UtilService,
    private route: ActivatedRoute,
    private loader: LoaderService,
    private message: MessageService
  ) {

  }

  togglePopup() {
    this.showRegisterPopup = true;
  }

  ngOnInit() {
    this.loader.showLoader.subscribe(showLoader => {
      this.showLoader = showLoader;
    });


    this.loader.showMessage.subscribe(showMessage => {
      this.showMessage = showMessage;
    });

    console.warn(this.route.snapshot);

    if (this.route.snapshot.params.path == 'loggedout') {
      console.warn('user cookies removed');
      this.util.deleteCookie('admin_token');
      this.util.deleteCookie('admin_role');
    }

    if (this.session.isLoggedIn()) {
      this.router.navigateByUrl('/dashboard');
    }
    this.loader.setShowLoader(false);
  }

  processLogin() {
    this.loader.setShowLoader(true);

    this.candy.createRequest('POST', `api/login/admin?email=${this.loginUsername}&password=${this.loginPassword}`).subscribe(response => {
      console.warn(response);

      if (response) {
            this.util.setCookie('admin_token', response['access_token']); // 4 = 4 hours to match magento's token timeout
            this.util.setCookie('admin_refresh_token', response['refresh_token']);
            this.util.setCookie('admin_role', response['role']); // save the role to determine user access to API calls

            this.checkBasket(); // check if logged in user has basket, if so, add it to local storage
            this.router.navigateByUrl('/dashboard');
          }
          this.loader.setShowLoader(false);
      }, error => {
      console.warn(error);
      this.showUserDNE = true;
      this.errorMessage = error.error.message;
      this.message.showMessage('Incorrect username or password', 'error');
      this.loader.setShowLoader(false);
    });
  }

  checkBasket() {
    this.candy.createRequest('GET', 'api/v1/baskets/current').subscribe(response => {
      console.warn(response);
      const basketID = response['data']['id'];
      if (basketID) {
        const itemCount = response['data']['lines']['data'].length;
        localStorage.setItem('cart', JSON.stringify({basket_id: basketID, item_count: itemCount}));
      }
    });
  }

}


